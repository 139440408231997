import { css } from '@emotion/css';

export const styAuthWrapper = css`
  &[data-n-desktop='true'] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 600px calc(100vw - 600px);
  }

  .box__wrapper {
    &[data-n-desktop='true'] {
      max-width: 100%;
      background-color: #e6dbd9;
    }
  }

  .box__outer-container {
    &[data-n-desktop='true'] {
      box-shadow: 2xl;
      display: block;
      margin: auto;
      min-height: 100vh;
      background-color: '';
      padding: 24px;
      height: 100vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .box__border-container {
    &[data-n-desktop='true'] {
      background-color: #f0ede6;
      border: 1px solid black;
      border-radius: 32px;
      padding: 24px;
      width: 500px;
    }
  }
`;
