import React from 'react';
import { bool, func } from 'prop-types';

import {
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
} from '@chakra-ui/react';

function ModalAnnouncement({ onClose, visible }) {
  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent bgColor="#F0EDE6">
        <DrawerCloseButton marginTop="12px" />
        <DrawerHeader
          fontFamily="Newsreader"
          borderBottomWidth="1px"
          borderBottomColor="black"
          fontSize="xl"
          fontWeight="bold"
          color="black"
          textAlign="left"
        >
          Apa itu Link Announcement?
        </DrawerHeader>
        <DrawerBody>
          <Text align="left" fontSize="md" fontFamily="Newsreader" margin="16px 0" color="black">
            <b>Link Announcement</b> adalah sebuah website pemberitahuan yang ditujukan kepada
            orang-orang yang memang tidak terundang secara langsung, sehingga isi fitur di dalamnya
            tidak komplit. Di dalam website pemberitahuan ini <b>tidak ada fitur</b> acara resepsi
            (hanya akad/pemberkatan), RSVP, tombol ingatkan acara, tombol lihat peta, dan lain
            sebagainya.
          </Text>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalAnnouncement.propTypes = {
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

export default ModalAnnouncement;
