import React, { useState } from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import { FaArrowLeft } from 'react-icons/fa';

import Auth from './components/Auth';
import CustomScreen from './components/CustomScreen';
import ListGuestScreen from './components/ListGuestScreen';
import WelcomeScreen from './components/WelcomeScreen';

import * as T from './types';

/**
 * Function to render Link Generator
 * @returns {JSX.Element}
 */
function LinkGenerator() {
  const [displayState, setDisplayState] = useState(T.STATE_DISPLAY.welcome);
  const [mode, setMode] = useState(T.CUSTOM_MODE.invitation);

  const setCustomInvitation = (type) => {
    setDisplayState(T.STATE_DISPLAY.custom);
    setMode(type);
  };

  return (
    <Auth>
      <Box>
        {displayState === T.STATE_DISPLAY.list && (
          <ListGuestScreen type={T.CUSTOM_MODE.invitation} />
        )}
        {displayState === T.STATE_DISPLAY.custom && <CustomScreen type={mode} />}
        {displayState === T.STATE_DISPLAY.welcome && (
          <WelcomeScreen
            onSetCustomInvitation={setCustomInvitation}
            onSetDisplay={setDisplayState}
          />
        )}

        {/* Floating Button */}
        {/* Only render component if not in welcome page */}
        {displayState !== T.STATE_DISPLAY.welcome && (
          <IconButton
            colorScheme="black"
            className="animation-heart"
            isRound
            icon={<FaArrowLeft color="black" />}
            onClick={() => setDisplayState(T.STATE_DISPLAY.welcome)}
            // custom css styles
            boxShadow="xl"
            border="1px"
            bgColor="blackAlpha.400"
            borderColor="black"
            position="fixed"
            bottom="14px"
            left="20px"
          />
        )}
      </Box>
    </Auth>
  );
}

export default LinkGenerator;
